import { CustomerProductRegistration } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import VueRecaptcha from 'vue-recaptcha'

Component.registerHooks(['beforeRouteLeave'])

@Component({
    components: { VueRecaptcha }
})
export default class Registration extends Vue {
    loading = false
    showDialog = false
    showDatePicker = false
    isCaptchaValidated = false
    selectModelNameValue = 0
    message = ''
    yesBtn = {
        text: 'Yes',
        show: true
    }
    noBtn = {
        text: 'No',
        show: true
    }
    okBtn = {
        text: 'Ok',
        show: false
    }
    confirmLeave = false
    customerProductRegistration = {
        state: '',
        sku: ''
    } as CustomerProductRegistration
    products = [
        {
            sku: '10421',
            description: '10421',
            type: 1
        },
        {
            sku: '10353',
            description: `10353 : 2pk Seats`,
            type: 2
        },
        {
            sku: '10261',
            description: `10261 : 42" x 48" (4 Seat)`,
            type: 2
        },
        {
            sku: '10262',
            description: `10262 : 48" x 72" (4 Seat)`,
            type: 2
        },
        {
            sku: '10263',
            description: `10263 : 48" x 72" (6 Seat)`,
            type: 2
        },
        {
            sku: '10264',
            description: `10264 : 42" x 72" (8 Seat)`,
            type: 2
        }
    ]
    state = [
        { text: '', value: '' },
        { text: '--- USA', value: '--- USA', header: '--- USA' },
        { text: 'Alabama', value: 'AL' },
        { text: 'Alaska', value: 'AK' },
        { text: 'Arizona', value: 'AZ' },
        { text: 'Arkansas', value: 'AR' },
        { text: 'California', value: 'CA' },
        { text: 'Colorado', value: 'CO' },
        { text: 'Connecticut', value: 'CT' },
        { text: 'Delaware', value: 'DE' },
        { text: 'Florida', value: 'FL' },
        { text: 'Georgia', value: 'GE' },
        { text: 'Hawaii', value: 'HI' },
        { text: 'Idaho', value: 'ID' },
        { text: 'Illinois', value: 'IL' },
        { text: 'Indiana', value: 'IN' },
        { text: 'Iowa', value: 'IA' },
        { text: 'Kansas', value: 'KS' },
        { text: 'Kentucky', value: 'KY' },
        { text: 'Louisiana', value: 'LA' },
        { text: 'Maine', value: 'ME' },
        { text: 'Maryland', value: 'MD' },
        { text: 'Massachusetts', value: 'MA' },
        { text: 'Michigan', value: 'MI' },
        { text: 'Minnesota', value: 'MN' },
        { text: 'Mississippi', value: 'MS' },
        { text: 'Missouri', value: 'MO' },
        { text: 'Montana', value: 'MT' },
        { text: 'Nebraska', value: 'NE' },
        { text: 'Nevada', value: 'NV' },
        { text: 'New Hampshire', value: 'NH' },
        { text: 'New Jersey', value: 'NJ' },
        { text: 'New Mexico', value: 'NM' },
        { text: 'New York', value: 'NY' },
        { text: 'North Carolina', value: 'NC' },
        { text: 'North Dakota', value: 'ND' },
        { text: 'Ohio', value: 'OH' },
        { text: 'Oklahoma', value: 'OK' },
        { text: 'Oregon', value: 'OR' },
        { text: 'Pennsylvania', value: 'PA' },
        { text: 'Rhode Island', value: 'RI' },
        { text: 'South Carolina', value: 'SC' },
        { text: 'South Dakota', value: 'SD' },
        { text: 'Tennessee', value: 'TN' },
        { text: 'Texas', value: 'TX' },
        { text: 'Utah', value: 'UT' },
        { text: 'Vermont', value: 'VT' },
        { text: 'Virginia', value: 'VA' },
        { text: 'Washington', value: 'WA' },
        { text: 'West Virginia', value: 'WV' },
        { text: 'Wisconsin', value: 'WI' },
        { text: 'Wyoming', value: 'WY' },
        { text: '--- CANADA', value: '--- CANADA', header: '--- CANADA' },
        { text: 'Alberta', value: 'AB' },
        { text: 'British Columbia', value: 'BC' },
        { text: 'Manitoba', value: 'MB' },
        { text: 'New Brunswick', value: 'NB' },
        { text: 'Newfoundland/Labrador', value: 'NL' },
        { text: 'Nova Scotia', value: 'NS' },
        { text: 'Northwest Territories', value: 'NT' },
        { text: 'Nunavut', value: 'NU' },
        { text: 'Ontario', value: 'ON' },
        { text: 'Prince Edward Island', value: 'PE' },
        { text: 'Quebec', value: 'QC' },
        { text: 'Saskatchewan', value: 'SK' },
        { text: 'Yukon', value: 'YT' },
        { text: '--- TERRITORIES', value: '--- TERRITORIES', header: '--- TERRITORIES' },
        { text: 'American Samoa', value: 'AS' },
        { text: 'District of Columbia', value: 'DC' },
        { text: 'Federated States of Micronesia', value: 'FM' },
        { text: 'Guam', value: 'GU' },
        { text: 'Marshall Islands', value: 'MH' },
        { text: 'Northern Mariana Islands', value: 'MP' },
        { text: 'Palau', value: 'PW' },
        { text: 'Puerto Rico', value: 'PR' },
        { text: 'Virgin Islands', value: 'VI' }
    ]
    rules = {
        required: (name: string) => {
            return (value: any) => !!value || `${name} is required`
        },
        email: (value: string) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail must be valid'
        }
    }

    get modelNameList() {
        return [
            { text: '-- Select One --', value: 0 },
            { text: 'BABY CHANGER SOFT', value: 1 },
            { text: `INTERACTIVE CHILDREN'S TABLE`, value: 2 }
        ]
    }

    get modelNumberList() {
        const result = [{ text: '-- Select One --', value: '' }]
        return result.concat(
            this.products
                .filter((x) => x.type == this.selectModelNameValue)
                .map((x: any) => {
                    return { text: x.description, value: x.sku }
                })
        )
    }

    get formatDate() {
        if (!this.customerProductRegistration.manufactureDate) return ''
        const [year, month, day] = this.customerProductRegistration.manufactureDate.split('-')
        return `${month}/${year}`
    }

    set formatDate(date) {
        this.customerProductRegistration.manufactureDate = date
    }

    beforeRouteLeave(to: any, from: any, next: any) {
        if (!this.confirmLeave) {
            this.yesBtn.show = this.noBtn.show = true
            this.okBtn.show = false
            this.showDialog = true
            this.message = 'Are you sure you wish to navigate away from this page?'
        }
        next(this.confirmLeave)
    }

    created() {
        const meta = document.createElement('meta')
        meta.name = 'robots'
        meta.content = 'noindex'
        document.getElementsByTagName('head')[0].appendChild(meta)
    }

    destroyed() {
        document.querySelector('meta[name="robots"][content="noindex"]')?.remove()
    }

    register() {
        const form: any = this.$refs.form
        if (form.validate() && !this.loading) {
            this.loading = true
            this.$axios
                .post('/api/utilities/product-registration', this.customerProductRegistration)
                .then((res) => {
                    this.yesBtn.show = this.noBtn.show = false
                    this.okBtn.show = true
                    this.showDialog = true
                    this.message = 'Thank you for registering your FDP product. Please retain the printed card received with your product for reference.'
                    this.loading = false
                    this.expiredCapcha()
                })
        }
    }

    yesAction() {
        this.confirmLeave = true
        this.$router.push({ name: 'Home' })
    }

    okAction() {
        const form: any = this.$refs.form
        form.reset()
        this.showDialog = false
    }

    cancelAction() {
        this.yesBtn.show = this.noBtn.show = true
        this.okBtn.show = false
        this.showDialog = true
        this.message = 'Are you sure you wish to navigate away from this page?'
    }

    verifyCapcha() {
        this.isCaptchaValidated = true
    }

    expiredCapcha() {
        const captcha: any = this.$refs.recaptcha
        captcha.reset()
        this.isCaptchaValidated = false
    }

    onModelNameChange(value: any) {
        this.customerProductRegistration.modelName = this.modelNameList.find(x => x.value == this.selectModelNameValue)?.text
        this.customerProductRegistration.sku = ''
    }

    mounted() {
        this.setRecaptchaAriaLabel();
    }

    setRecaptchaAriaLabel() {
        const recaptchaComponent = this.$refs.recaptcha as Vue & { $el: () => any };

        if (recaptchaComponent) {
            const recaptchaElement = recaptchaComponent.$el.querySelector("textarea");
            recaptchaElement?.setAttribute('aria-label', 'reCapcha Verification');
        }
    }
}